<template>
  <div>
    <TabPageLayout activeCode="yujianyizhong">
      <div class="container scenery">
        <div class="div_mod div_mod1 div_hidden">
          <div class="div_r">
            <div class="div_tit">
              <!-- 栏目名称 -->
              <h2 v-if="blocks?.length">{{blocks[0]?.title}}</h2>
            </div>
            <div v-if="blocks?.length">
              <div class="div_text ell_text" v-html="blocks[0]?.bz"></div>
            </div>
          </div>
          <div class="div_l">
            <ul>
              <li
                class="li1"
                v-for="item in schoolYear"
                :key="item.id"
                @click="handleDialogOpen(item)"
              >
                <a>
                  <!-- TODO 点以后弹出弹层展示其有关的所有文章 -->
                  <img :src="item.bgimg" :alt="item.title" />
                  <span>{{ item.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="div_mod div_mod2">
          <div class="div_tit">
            <h2 v-if="blocks?.length">{{blocks[1]?.title}}</h2>
          </div>
          <div v-if="blocks?.length">
            <div class="div_text" v-html="blocks[1]?.bz"></div>
          </div>

          <div class="lunbo">
            <!-- 所有有关文章展示 -->
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="aside in schoolAside"
                  :key="aside.id"
                  @click="handleDialogOpen(aside)"
                >
                  <div class="imgbox">
                    <img :src="aside.bgimg" alt="" />
                  </div>
                  <p>{{ aside.title }}</p>
                </div>
              </div>
            </div>
            <div class="direction">
              <span class="s_prev"><i></i></span>
              <span class="s_next"><i></i></span>
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>

    <CommonDialog
      :visible.sync="oDialog.visible"
      :titleText.sync="oDialog.title"
      :showCancel="false"
      :showOK="false"
      :size="oDialog.size"
    >
      <SwiperContainer v-if="oDialog.visible" :typeId="oDialog.typeId + ''" showContent />
    </CommonDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import { useGetCategories } from './utils/useGetCategories'
import CommonDialog from '../../../lvya-common/src/coms-web/dialog/CommonDialog.vue'
import SwiperContainer from './components/SwiperContainer.vue'

export default {
  components: { TabPageLayout, CommonDialog, SwiperContainer },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    sceneryId() {
      let sceneryId = ''
      this.nav
        ?.find((item) => item.code === 'yujianyizhong')
        ?.stypes?.forEach((nav) => {
          console.log(nav)
          if (nav.code === 'yizhongfengguang') {
            sceneryId = nav.id
          }
        }) || []
      return sceneryId
    },
    schoolYear() {},
  },
  data() {
    return {
      blocks: [],
      schoolYear: [],
      schoolAside: [],
      oDialog: {
        visible: false,
        title: ' ',
        typeId: null,
      },
      swiper: null,
    }
  },
  methods: {
    // 导航栏
    async getNavList() {
      let resData = await useGetCategories(this.sceneryId)
      console.log(resData, 'resDataresDataresData')
      this.blocks = resData?.list || []
      console.log(resData, this.blocks)
      for (let index = 0; index < resData?.list?.length; index++) {
        const nav = resData?.list[index]
        if (index === 0) {
          const resData = await portalApi.getTypeListByPage({ pId: nav.id })
          this.schoolYear = resData.list
        } else if (index === 1) {
          const resData = await portalApi.getTypeListByPage({ pId: nav.id })
          this.schoolAside = resData.list
        }
      }
    },
    handleDialogOpen(item) {
      console.log(item, ' item')
      this.oDialog.title = item.title
      this.oDialog.typeId = item.id
      this.oDialog.visible = true
    },
    initSwiper() {
      this.swiper = new Swiper('.scenery .div_mod2 .swiper-container', {
        watchSlidesVisibility: true,
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        navigation: {
          nextEl: '.lunbo .s_next',
          prevEl: '.lunbo .s_prev',
        },
        breakpoints: {
          1023: {
            // 当屏幕宽度大于等于102333
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      })
    },
    destroySwiper() {
      if (this.swiper) {
        this.swiper.destroy()
      }
    },
  },
  async mounted() {
    await this.getNavList()
    this.initSwiper()
  },
  beforeDestroy() {
    this.destroySwiper()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/about.css";

// .common-dialog {
//   /deep/.el-dialog {
//     position: relative;
//     background: transparent;
//     .el-dialog__header {
//       border-bottom: none;
//     }
//     .el-dialog__body {
//       height: auto;
//     }
//     .el-dialog__close {
//       color: #fff;
//       font-size: 25px;
//       position: relative;
//       left: 100%;
//     }
//   }
// }

.ell_text{
  max-height: 4rem;
  overflow: hidden;
  overflow-y: auto;
}
/deep/.el-dialog .el-dialog__body {
  height: auto;
  max-height: 70vh;
}

@media screen and (max-width: 768px) {
  /deep/.el-dialog {
    width: 80%;
  }
  .ell_text{
    max-height: 100% !important;
  }
}
</style>
