<template>
  <a @click="toDetail(article)">
    <div class="imgbox">
      <img :src="article.files" alt="" />
    </div>
    <div class="txtbox">
      <!-- <b>2024-07-17</b> -->
      <h3>
        {{ article.title }}
      </h3>
      <p>
        {{ article.des }}
      </p>
      <span @click="toDetail(article)">查看详情</span>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
    activeCode: {
      type: String,
      default: 'xinwendongtai',
    },
  },
  methods: {
    toDetail(article) {
      console.log(article, '1111111')
      if (article.url != '') {
        window.open(article.url)
      } else {
        this.$router.push({
          path: '/portalDetails',
          query: {
            id: article.id,
            activeCode: this.activeCode,
          },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
@import "../style/news.css";
</style>
